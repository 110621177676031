import VueJwtDecode from "vue-jwt-decode";

/**
 * Determine if the token expiration time will occur before `seconds` elapses
 * @param {string} jwt
 * @param {number} seconds
 * @returns {boolean}
 */
export function getJWTValidity(jwt) {
  const body = VueJwtDecode.decode(jwt);
  const now = Math.floor(new Date().getTime() / 1000);
  const hasIssuedAt = "iat" in body;
  const hasExpired = "exp" in body;
  const isAfterIssuedAt = hasIssuedAt && body.iat < now;
  const isBeforeExpired = hasExpired && now < body.exp;
  return {
    now,
    hasIssuedAt,
    hasExpired,
    isAfterIssuedAt,
    isBeforeExpired,
    issuedAt: body.iat ?? null,
    expiresAt: body.exp ?? null,
  };
}

export function isJWTValidForSeconds(jwt, seconds = 30) {
  const v = getJWTValidity(jwt);
  console.debug(v);
  return (
    (!v.hasIssuedAt || v.isAfterIssuedAt) &&
    (!v.hasExpired || v.now + seconds < v.expiresAt)
  );
}
