import { ref, computed, watch } from "vue";
import { defineStore } from "pinia";
import useApi from "@/services/useApi";
import { isJWTValidForSeconds } from "../etc/utils/jwt";
import { useAuthUserStore } from "./authUser";

/**
 * @typedef {Object} AffiliateActivityRequest
 * @property {string} activityShortId
 * @property {string} amount regex(/^\d+(?:\.\d+)$/),
 * @property {string} currentActor // The key to use in the users object for the email contained in the jwt for the activity
 * @property {number} [activityTimestamp]
 * @property {string} [description]
 * @property {string} [referenceId]
 * @property {Record<string,string>} [users]
 */

/**
 * @typedef {Object} AffiliateActivityResponse
 * @property {string} id
 */

/**
 * @typedef {Object} AffiliateCodeResponse
 * @property {string} code
 */

/**
 * @typedef {Object} AffiliateSegmentAward
 * @property {string} denom  ex: '2';
 * @property {string} name  ex: 'Advanced';
 * @property {string} type  ex: 'SEGMENT';
 * @property {number} balance  ex: 1;
 */

/**
 * @typedef {Object} AffiliateAssetModel
 * @property {boolean} active  ex: true;
 * @property {string} name  ex: 'Chicken Treat';
 * @property {string} userFacingName  ex: 'Chicken Treat';
 * @property {unknown} nbDecimals  ex: TODO  null
 * @property {unknown} assetClass  ex: TODO  null
 * @property {number} assetType  ex: 1;
 * @property {unknown} rollingExpirationPeriodSec  ex: TODO  null
 * @property {unknown} bulkExpirationInactivitySec  ex: TODO  null
 * @property {unknown} bulkExpirationSlashRatePpm  ex: TODO  null
 * @property {unknown} clawbackPeriodSec  ex: TODO  null
 * @property {{ [key: string]: unknown }} details  ex: TODO
 */

/**
 * @typedef {Object} AffiliateAssetAward
 * @property {string} denom  ex: 'maunor-chicken-trea';
 * @property {number} balance  ex: 97904.339059;
 * @property {string} currency  ex: 'USD';
 * @property {AffiliateAssetModel} assetModel
 * @property {string} name  ex: 'Chicken Treat';
 * @property {string} type  ex: 'ASSET';
 */

/**
 * @typedef {Object} AffiliateUserAwardsResponse
 * @property {string} network ex: 'maunor';
 * @property {string} userEmail ex: 'user@villagelabs.co';
 * @property {Array<AffiliateSegmentAward | AffiliateAssetAward>} awards ;
 * @property {string} status ex: 'success';
 * @property {string} message ex: 'Awards retrieved successfully for the user';
 */

/**
 * @typedef {Object} AffiliateApplyCreditToOrderResponse
 * @property {WooCommerceOrderSummary} order updated wooCommerce order
 */

/**
 * @typedef {Object} AffiliateRemoveCreditFromOrderResponse
 * @property {WooCommerceOrderSummary} order updated wooCommerce order
 */

/**
 * @typedef {Object} AffiliateApplyReferrerToOrderResponse
 * @property {WooCommerceOrderSummary} order updated wooCommerce order
 */

export const useAffiliateStore = defineStore(
  "affiliate",
  () => {
    // state
    /** @type {string|null} */
    const affiliateCode = ref(null);
    /** @type {AffiliateUserAwardsResponse|null} */
    const awards = ref(null);
    /** @type {string|null} */
    const villageAuth = ref(null);
    /** @type {string|null} */
    const referrerCode = ref(null);

    /** @type {string|null} */
    const affiliateCodeError = ref(null);
    /** @type {string|null} */
    const awardsError = ref(null);

    // used to force a recompute of the auth computed property when an expired error is detected
    const authCacheBust = ref(0);

    // stores
    const authUserStore = useAuthUserStore();

    // getters
    const auth = computed(() => {
      authCacheBust.value;
      if (
        authUserStore.authUser &&
        isJWTValidForSeconds(authUserStore.authUser, 30)
      ) {
        return authUserStore.authUser;
      }
      return null;
    });
    const email = computed(() => authUserStore.email);
    const availableAwardBalance = computed(() => {
      const amount = awards.value?.awards?.reduce((acc, award) => {
        return acc + award.balance;
      }, 0);
      return awards.value ? amount : null;
    });
    const formatedAvailableAwardBalance = computed(() => {
      if (availableAwardBalance.value !== null) {
        return `$${
          availableAwardBalance.value === 0
            ? "000.00"
            : availableAwardBalance.value.toFixed(2)
        }`;
      }
      return "-";
    });

    const isVillageAuthUsable = computed(() => {
      if (villageAuth.value) {
        return isJWTValidForSeconds(villageAuth.value);
      }
      return false;
    });
    const isAuthExpired = computed(() => {
      if (authUserStore.authUser) {
        return !isJWTValidForSeconds(authUserStore.authUser, 30);
      }
      return null;
    });

    const affiliateLink = computed(() =>
      affiliateCode.value
        ? `${window.location.origin}/referral?code=${affiliateCode.value}`
        : null
    );

    const canApplyReferrerCode = computed(() => !!referrerCode.value);

    // actions
    async function init() {
      affiliateCode.value = "";
      awards.value = null;

      if (auth.value) {
        await refresh();
      }
    }

    // retrieve the affiliate information, without clearing the old result first.
    // use to fetch updated value, without causing a temporary blank as it is being retrieved
    async function refresh() {
      if (auth.value) {
        await Promise.all([getAffiliateCode(), getAffiliateAwards()]);
      }
    }

    async function getVillageAuth() {
      if (isVillageAuthUsable.value) {
        return villageAuth.value;
      }
      villageAuth.value = null;
      // TODO if we have auth, look to refresh it
      return null;
    }

    /**
     * @param {import("axios").AxiosRequestConfig} request
     */
    async function api(request) {
      if (auth.value === null)
        return Promise.reject(
          new Error("Cannot make affiliate api request until it is initialized")
        );

      // using the method instead of the computed value, not sure that it would
      // get updated if the page load occured when the jwt was valid, and then
      // later expired
      if (!isJWTValidForSeconds(auth.value, 30)) {
        // force the auth to be re-computed
        authCacheBust.value += 1;
        return Promise.reject(
          new Error("Authentication token expired, please login again")
        );
      }
      return useApi({
        ...request,
        params: {
          jwt: auth.value,
          ...request.params,
        },
      }).catch((err) => {
        if (
          err.isAxiosError === true &&
          err.response?.status === 400 &&
          (err.response?.data?.message === "Invalid authorization provided" ||
            (err.response?.data?.name === "ValidationError" &&
              err.response?.data?.reasons?.jwt === "jwt expired"))
        ) {
          // auth is now invalid
          authUserStore.refreshAuth();
        }
        return Promise.reject(err);
      });
    }

    async function getAffiliateCode() {
      try {
        /** @type {import("axios").AxiosResponse<AffiliateCodeResponse>} */
        const codeResponse = await api({
          method: "GET",
          url: "/affiliate/get-user-affiliate-code",
        });
        affiliateCode.value = codeResponse.data.code;
      } catch (err) {
        affiliateCode.value = null;
        affiliateCodeError.value =
          err?.response?.data?.message ?? err?.message ?? "An error occured";
        console.error(err);
      }
      return affiliateCode.value;
    }

    async function getAffiliateAwards() {
      try {
        /** @type {import("axios").AxiosResponse<AffiliateUserAwardsResponse>} */
        const awardsResponse = await api({
          method: "GET",
          url: "/affiliate/get-awards",
        });
        awards.value = awardsResponse.data;
      } catch (err) {
        awards.value = [];
        awardsError.value = err?.message ?? "An error occured";
        console.error(err);
      }
      return awards.value;
    }

    /**
     * @param {AffiliateActivityRequest} activity
     */
    async function postActivity(activity) {
      /** @type {import("axios").AxiosResponse<AffiliateActivityResponse>} */
      const activityResponse = await api({
        method: "POST",
        url: "/affiliate/post-activity",
        data: activity,
      });

      return activityResponse.data;
    }

    /**
     * Will apply as much credit as possible to the order, and will return the updated order.
     *
     * If for whatever reason this needs to be undo, you can use the removeCreditFromOrder action
     * @param {number} orderId
     */
    async function applyCreditToOrder(orderId) {
      /** @type {import("axios").AxiosResponse<AffiliateApplyCreditToOrderResponse>} */
      const response = await api({
        method: "POST",
        url: "/affiliate/apply-credit-to-order",
        data: {
          orderId,
        },
      });
      return response.data.order;
    }

    /**
     * Will remove credit that were applied to an order, returning them to the village balance
     * @param {number} orderId
     */
    async function removeCreditFromOrder(orderId) {
      /** @type {import("axios").AxiosResponse<AffiliateRemoveCreditFromOrderResponse>} */
      const response = await api({
        method: "POST",
        url: "/affiliate/remove-credit-from-order",
        data: {
          orderId,
        },
      });
      return response.data.order;
    }

    function isDiscountFromAffiliateCredit(order) {
      return !!order.coupon_lines?.find((line) =>
        line.code.startsWith(`aff${order.orderId}`)
      );
    }

    async function applyReferrerToOrderHandler(orderId) {
      if (!canApplyReferrerCode.value) return null;
      // this call is intended to be made un-authenticated
      /** @type {import("axios").AxiosResponse<AffiliateApplyReferrerToOrderResponse>} */
      const response = await useApi({
        method: "POST",
        url: "/affiliate/apply-referrer-to-order",
        data: {
          orderId,
          referrer: referrerCode.value,
        },
      });
      return response.data.order;
    }

    // watches
    watch(
      auth,
      () => {
        init();
      },
      { immediate: true }
    );

    return {
      // state
      affiliateCode,
      affiliateCodeError,
      awards,
      awardsError,
      villageAuth,
      referrerCode,
      // getters
      auth,
      email,
      availableAwardBalance,
      formatedAvailableAwardBalance,
      isVillageAuthUsable,
      isAuthExpired,
      affiliateLink,
      canApplyReferrerCode,
      // actions
      init,
      refresh,
      getVillageAuth,
      api,
      getAffiliateCode,
      getAffiliateAwards,
      postActivity,
      applyCreditToOrder,
      removeCreditFromOrder,
      applyReferrerToOrderHandler,
      isDiscountFromAffiliateCredit,
    };
  },
  {
    persist: {
      paths: ["villageAuth", "referrerCode", "affiliateCode"],
      storage: sessionStorage,
    },
  }
);
