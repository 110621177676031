<template>
  <div ref="container" class="imgix-image-container" :class="attrs.class">
    <img
      v-if="!isImgixError"
      ref="image"
      :alt="alt"
      :src="imageSrc"
      :srcset="imageSrcSet"
      :style="imageStyle"
      :class="attrs.class"
      :fetchpriority="props.fetchPriority"
      @error="setImgixError"
    />
    <img
      v-else
      :alt="alt"
      :src="src"
      :style="imageStyle"
      :class="attrs.class"
      :fetchpriority="props.fetchPriority"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
// eslint-disable-next-line import/first
import { ref, onMounted, onBeforeUnmount, computed, useAttrs } from "vue";

const attrs = useAttrs();

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: null,
    required: false,
  },
  useHeight: {
    type: Boolean,
    default: false,
  },
  useHeightAndWidth: {
    type: Boolean,
    default: false,
  },
  height: {
    type: Number,
    default: undefined,
  },
  crop: {
    type: String,
    default: undefined,
  },
  fit: {
    type: String,
    default: "clip",
  },
  fetchPriority: {
    type: String,
    default: "auto",
  },
  imgStyle: {
    type: Object,
    default: () => ({}),
  },
});

const container = ref(null);
const image = ref(null);
const containerWidth = ref(null);
const containerHeight = ref(null);
const isImgixError = ref(false);

const supportedDprs = [1, 2, 3];

const setImgixError = () => {
  isImgixError.value = true;
};

const calculateWidth = () => {
  // console.log("calculateWidth");
  if (container.value?.offsetWidth !== 0) {
    containerWidth.value = container.value.offsetWidth;
  }
};

const calculateHeight = () => {
  // console.log("calculateHeight");
  if (container.value.offsetHeight !== 0) {
    containerHeight.value = container.value.offsetHeight;
  }
};

// Force re-calculation of width when element goes from hidden to visible
const setupIntersectionObserver = () => {
  if (!window.IntersectionObserver) return;
  const observer = new window.IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        calculateWidth();
        calculateHeight();
        observer.unobserve(entry.target);
      }
    });
  });

  observer.observe(container.value);
};

onMounted(() => {
  calculateWidth();
  calculateHeight();
  setupIntersectionObserver();
});

onBeforeUnmount(() => {});

const imageStyle = computed(() => ({
  width: "100%",
  ...props.imgStyle,
}));

// TO DO: keep track of previously loaded images, only load new images in they are bigger.

const source = computed(() => {
  const { src } = props;

  if (src.startsWith("@/assets/")) {
    return src.slice("@/assets/".length);
  }
  if (src.startsWith("/src/assets/")) {
    return src.slice("/src/assets/".length);
  }
  return src;
});

const imageSrc = computed(() => {
  if (containerWidth.value) {
    const queryParams = {
      auto: "format",
      w: containerWidth.value,
    };

    queryParams.fit = props.fit;

    if (props.useHeight || props.useHeightAndWidth) {
      queryParams.h = containerHeight.value;
      if (!props.useHeightAndWidth) {
        delete queryParams.w;
      }
      // console.log("useHeight");
      // console.log("queryParams", queryParams);
    }

    if (props.height) {
      queryParams.h = props.height;
      queryParams.fit = "crop";
    }

    if (props.crop) {
      queryParams.h = containerHeight.value;
      queryParams.fit = "crop";
      queryParams.crop = props.crop;
      // console.log("useHeight");
      // console.log("queryParams", queryParams);
    }

    const server = "https://w2w-web-media.imgix.net/";

    const params = new URLSearchParams(queryParams);
    const srcWithParams = `${server + source.value}?${params.toString()}`;
    // console.log("srcWithParams", srcWithParams);
    return srcWithParams;
  }
  return null; // linter complains
});

const imageSrcSet = computed(() => {
  if (!imageSrc.value) return null; // e.g. if image isn't visible yet
  const srcSet = supportedDprs
    .map((dpr) => {
      const srcWithParams = `${imageSrc.value}&dpr=${dpr}`;
      return `${srcWithParams} ${dpr}x`;
    })
    .join(", ");

  return srcSet;
});
</script>

<style scoped>
.imgix-image-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.company-header,
.hero-banner {
  position: absolute;
  height: 100%;
  object-fit: cover;
}

.hero-banner {
  z-index: -1;
  border-radius: 8px;
}
</style>
