<script setup>
import { computed } from "vue";
import { VueFinalModal } from "vue-final-modal";
import SVGIcon from "@/components/SVGIcon.vue";

const props = defineProps({
  modelValue: Boolean,
  success: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
  mobileFooter: { type: Boolean, default: false },
  closable: { type: Boolean, default: true },
});
const emit = defineEmits(["update:modelValue", "closed"]);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <vue-final-modal
    v-model="model"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="closable"
    @closed="emit('closed')"
  >
    <div class="modal-content-wrapper" :class="{ success, error }">
      <div v-if="closable" class="close-button" @click="model = false">
        <SVGIcon width="28" height="28" name="close-small" />
      </div>
      <div class="modal-slot"><slot /></div>

      <div
        v-if="mobileFooter"
        class="modal-footer hidden-desktop hidden-tablet"
      >
        <slot name="footer" />
      </div>
    </div>
  </vue-final-modal>
</template>

<style scoped src="./style.scss" />
