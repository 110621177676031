<script setup>
defineProps({
  color: { type: String, default: "blue" }, // blue, magenta, gold, hide
  width: { type: String, default: "557" },
  height: { type: String, default: "524" },
  top: { type: String, default: "" },
  bottom: { type: String, default: "" },
  right: { type: String, default: "" },
  left: { type: String, default: "" },
  opacity: { type: String, default: "0.7" },
  rotate: { type: String, default: "0" },
  blur: { type: String, default: "150" },
  z: { type: String, default: "-1" },
  mirror: { type: Boolean, default: false },
  mobile: { type: Boolean, default: false },
  desktop: { type: Boolean, default: false },
});

const position = (value) => {
  if (!value) return null;

  return value.toString().startsWith("calc") ? value : `${value}px`;
};
</script>

<template>
  <div
    class="spot"
    :style="{
      width: `${width}px`,
      height: `${height}px`,
      top: position(top),
      bottom: position(bottom),
      right: position(right),
      left: position(left),
      'z-index': z,
    }"
    :class="[color, { 'hidden-desktop': mobile, 'hidden-mobile': desktop }]"
  >
    <svg
      :style="{
        transform: `rotate(${rotate}deg) scale(${mirror ? '-1, 1' : '1'})`,
        filter: `blur(${blur}px)`,
      }"
      :width="width"
      :height="height"
      viewBox="0 0 557 524"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :opacity="opacity"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M551.579 199.997C565.979 263.169 550.862 331.7 516.048 386.271C480.028 442.73 422.217 482.148 358.59 502.505C295.213 522.782 216.396 540.007 163.957 498.921C111.04 457.461 139.585 370.053 111.444 308.87C91.7097 265.961 40.8846 244.99 26.4733 199.997C8.2019 142.953 -19.8394 68.9567 20.4359 24.7369C61.8117 -20.6912 138.042 13.0686 199.29 10.2555C241.139 8.33347 281.073 1.42369 321.891 10.8916C366.345 21.203 407.953 37.7929 443.234 66.8396C488.482 104.093 538.524 142.726 551.579 199.997Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<style src="./style.scss" lang="scss" scoped></style>
