import { defineStore } from "pinia";
import VueJwtDecode from "vue-jwt-decode";
export const useAuthUserStore = defineStore("authUser", {
  persist: {
    paths: ["authUser"],
    storage: sessionStorage,
  },
  state: () => ({
    authUser: null,
  }),
  getters: {
    /**
     *
     * @returns {string|null}
     */
    email() {
      if (!this.authUser) return null;

      const payload = VueJwtDecode.decode(this.authUser);
      return payload?.email ?? null;
    },
  },

  actions: {
    setAuthUserData(authUserData) {
      this.authUser = authUserData;
    },
    refreshAuth() {
      // there's nothing to do this at the moment, remove expired token
      this.authUser = null;
    },
  },
});
