<script setup>
import { RouterView } from "vue-router";
import NavBar from "@components/NavBar/NavBar.vue";
import FooterLinkWithLegalSection from "@components/Footers/FooterLinkWithLegalSection/FooterLinkWithLegalSection.vue";

import { useHubspotTracking } from "@/mixins/useHubspotTracking";
useHubspotTracking();
</script>

<template>
  <NavBar />

  <router-view v-slot="{ Component }">
    <Suspense>
      <component :is="Component" />
    </Suspense>
  </router-view>

  <FooterLinkWithLegalSection />
</template>

<style src="./style.scss" lang="scss"></style>
