import { useRoute } from "vue-router";
import { watch } from "vue";

/**
 * Meant to be used in the App.vue file, this will make the required
 * calls to hubspot to send the tracking information when the page
 * change is made within the app.
 *
 * more info here:
 * https://developers.hubspot.com/docs/api/events/tracking-code#tracking-in-single-page-applications
 */

export function useHubspotTracking() {
  const route = useRoute();

  const key = "_hsq";
  if (!(key in window)) {
    window[key] = [];
  }

  // Set up the path for the initial page view
  window[key].push(route.fullPath);

  // Tracking subsequent page views
  watch(
    () => route.fullPath,
    (url) => {
      window[key].push(["setPath", url]);
      window[key].push(["trackPageView"]);
    }
  );
}
